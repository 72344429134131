div.location h3 {
    color: #000;
    font-family: Heavitas, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

div.location p.location {
    color: rgba(0, 0, 0);
    font-family: Kanit, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

div.location {
    padding-top: 50px;
    padding-bottom: 60px;
}

.titlecontact {
    color: #000;
    font-family: Kanit, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.textcontact {
    color: #000;
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media(max-width:450px) {
    div.location h3 {
        font-size: 28px;
    }
}