* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.boxshadow {
  background: linear-gradient(90deg, #02445C 0%, #01162B 100%);
  box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.50);
}

.navbar-toggler{
  color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-wrap: nowrap;
}

.zindex2 {
  z-index: 2 !important;
}

body {
  background-color: #FFF;
}

.capa {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.header {
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.container .btn-menu label {
  color: #000;
  font-size: 18px;
  cursor: pointer;
  font-family: Kanit, sans-serif;
  display: inline-block;
  padding: 10px;
  line-height: normal;
}

.container .btn-menu label.menu {
  color: #000;
  font-size: 30px;
  cursor: pointer;
  font-family: Kanit, sans-serif;
  display: inline-block;
  padding: 15px;
  line-height: normal;
}

.cont-menu nav label {
  color: #000;
  font-size: 18px;
  cursor: pointer;
  font-family: Kanit, sans-serif;
  display: inline-block;
  padding: 10px;
  line-height: normal;
}

.logo h1 {
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  margin-left: 10px;
}

.navbar-expand-lg .navbar-nav .nav-link,
.dropdown-item {
  font-family: Kanit, sans-serif;
  display: inline-block;
  padding: 10px;
  line-height: normal;
  text-decoration: none;
  color: #F4F4F4;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 0;
}

.dropdown-item {
  color: #000;
}

.dropdown-item:hover {
  background-color: #d4d4d4 !important;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 10px;
}

#btn-menu {
  display: none;
}

.container-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}

#btn-menu:checked~.container-menu {
  opacity: 1;
  visibility: visible;
}

.cont-menu {
  height: 100%;
  width: 100%;
  max-width: 250px;
  background: #FFF;
  position: relative;
  transition: all 500ms ease;
  transform: translateX(-100%);
}

#btn-menu:checked~.container-menu .cont-menu {
  transform: translateX(0%);
}

.cont-menu nav {
  transform: translateY(10%);
}

.cont-menu nav label.menu {
  display: block;
  text-decoration: none;
  padding: 20px;
  color: #000000;
  border-left: 5px solid transparent;
  transition: all 400ms ease;
  margin-bottom: 0 !important;
}

.cont-menu nav label.menu:hover {
  border-left: 5px solid #c7c7c7;
  background: rgb(0, 0, 0, 0.2);
}

.cont-menu label.crossbar {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #78C354;
  cursor: pointer;
  font-size: 25px;
}


.btn-menu .rotate-arrow {
  transition: 0.5s ease-in-out;
}

#btn-menu:checked~.btn-menu .rotate-arrow {
  transform: rotate(180deg);
}

.rotate-arrow.rotated {
  transform: rotate(180deg);
}

.pointer {
  cursor: pointer;
}

.overflowmenu {
  overflow: hidden;
  overflow-x: scroll !important;
}

.overflowmenu::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

.overflowmenu::-webkit-scrollbar-track {
  background: #fff;
}

.overflowmenu::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 12px;
}

.overflowmenu::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.overflowmenu2 {
  overflow: hidden;
  overflow-y: scroll;
}

div.destacados img{
  border-radius: 12px;
}

a.Logo img{
    width: 60%;
}

@media(max-width:570px) {
  .Logo {
    width: 60%;
  }
}