div.banner {
    margin-top: 100px;
}

div.banner1 {
    border-radius: 5px;
    background: #FFC0EB;
    box-shadow: 0px 0px 250px -46px #F50087 inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.vermas {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 15px #FFF;
    font-family: Kanit, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    background: #737373;
    padding: 8px 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.homedecop {
    color: #000;
    font-family: Heavitas;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 150px;
    display: flex;
    align-items: center;
}


.dufour,
.cocot {
    width: 150px;
    height: 150px;
}

.vermas:hover {
    color: #dddddd;
}

div.banner2 {
    border-radius: 5px;
    background: #469BDC;
    box-shadow: 0px 0px 105.1px 80px rgba(0, 43, 92, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

div.banner3 {
    border-radius: 5px;
    background: #C9D2C0;
    box-shadow: 0px 4px 35.2px 17px rgba(115, 138, 66, 0.36) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

div.banner1 img.chicas,
div.banner2 img.chicos,
div.banner3 img.homedeco {
    position: absolute;
    height: 380px;
    bottom: 0;
}

@media(max-width:300px){
    div.banner3 img.homedeco{
        right: 10%;
    }
}

@media(min-width:300px){
    div.banner3 img.homedeco{
        right: 15%;
    }
}

@media(max-width:767px) {

    div.banner1 div {
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        top: 20px;
        position: relative;
    }

    div.banner2 div {
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        top: 20px;
        position: relative;
    }

    div.banner3 div {
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        top: 20px;
        position: relative;
    }

    div.banner1 img.chicas,
    div.banner2 img.chicos,
    div.banner3 img.homedeco {
        height: 250px;
    }

    div.banner1 img.chica {
        left: 10%;
    }

    div.banner1 img.chica2 {
        right: 10%;
    }

    div.banner2 img.chico {
        left: 10%;
    }

    div.banner2 img.chico2 {
        right: 10%;
    }

    div.chicos {
        margin-top: 100px;
    }

    img.homedeco {
        margin-top: 100px;
    }
}

@media(min-width:768px) {

    div.banner1 div {
        margin-left: initial;
        margin-right: initial;
    }

    div.banner2 div {
        margin-left: initial;
        margin-right: initial;
    }

    div.banner3 div {
        margin-left: initial;
        margin-right: initial;
    }

    div.banner1 img.chicas,
    div.banner2 img.chicos,
    div.banner3 img.homedeco {
        height: 250px;
    }

    div.banner1 img.chica {
        left: 5%;
    }

    div.banner1 img.chica2 {
        left: 25%;
    }

    div.banner2 img.chico {
        left: 13%;
    }

    div.banner2 img.chico2 {
        left: 55%;
    }
    div.banner3 img.homedeco{
        right: 0%;
    }

    .homedecop{
        max-width: 70%;
    }
}

@media(min-width:1200px) {

    div.banner1 img.chicas,
    div.banner2 img.chicos,
    div.banner3 img.homedeco {
        height: 300px;
    }

    div.banner1 img.chica {
        left: 3%;
    }

    div.banner1 img.chica2 {
        left: 24%;
    }

    div.banner2 img.chico {
        left: 35%;
    }

    div.banner2 img.chico2 {
        left: 50%;
    }

    div.banner3 img.homedeco{
        right: 0%;
    }

    div.banner1,
    div.banner2,
    div.banner3 {
        height: 280px;
    }
}