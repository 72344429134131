div.productosdata{
    background-color: #f3f3f3 !important;
}

p.category {
    color: rgba(0, 0, 0, 0.50);
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
span.category {
    color: rgba(0, 0, 0, 0.50);
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
span.categorys {
    color: rgba(0, 0, 0, 1);
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

p.titleproducts {
    color: #000;
    text-align: center;
    font-family: Heavitas, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.inv {
    color: transparent;
    font-family: Kanit, sans-serif;
    font-size: 12px;
}

@media(max-width:500px){
    p.category,span.category,span.categorys{
        font-size: 10px;
    }
    p.titleproducts{
        font-size: 14px;
        text-align: right;
    }
}