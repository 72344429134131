h3.destacados {
    color: #000 !important;
    font-family: Kanit, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

p.destacados {
    color: rgba(0, 0, 0);
    font-family: Kanit, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.slick-initialized .slick-slide {
    background-color: #FFF;
}

.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
    font-size: 30px !important
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    border-radius: 6px;
    display: block;
    width: 30px;
    height: 30px;
    z-index: 10;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:before,
.slick-next:before {
    color: #000;
}

div.destacados {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

div.destacados img a {
    margin-left: 10px;
    margin-right: 10px;
}

@media(max-width:450px) {

    div.containerdestacados div.destacados {
        height: 200px;
    }

    div.destacados {
        height: 220px;
        margin-left: 10px;
        margin-right: 10px;
    }

    div.destacados a img {
        width: 70px;
        height: 120px;
    }

    h3.destacados {
        font-size: 11px;
    }

    p.destacados {
        font-size: 7px;
    }
}

@media(min-width:450px) {
    div.destacados {
        height: 220px;
        margin-left: 10px;
        margin-right: 10px;
    }

    div.destacados a img {
        width: 70px;
        height: 120px;
    }

    h3.destacados {
        font-size: 11px;
    }

    p.destacados {
        font-size: 7px;
    }
}

@media(min-width:576px) {
    div.destacados {
        height: 220px;
        margin-left: 10px;
        margin-right: 10px;
    }

    div.destacados a img {
        width: 80%;
        height: 120px;
    }

    h3.destacados {
        font-size: 13px;
    }

    p.destacados {
        font-size: 8px;
    }
}

@media(min-width:768px) {
    div.destacados {
        height: 280px;
        margin-left: 10px;
        margin-right: 10px;
    }

    h3.destacados {
        font-size: 14px;
    }

    p.destacados {
        font-size: 7px;
    }
}

@media(min-width:992px) {
    div.destacados {
        height: 400px;
    }

    div.destacados a img {
        height: 130px;
    }

    div.destacados a {
        width: 80%;
        height: 150px;
    }

    h3.destacados {
        font-size: 14px;
    }

    p.destacados {
        font-size: 7px;
    }
}

@media(min-width:1200px) {
    div.destacados {
        height: 350px;
    }

    div.destacados a {
        height: 300px;
    }

    h3.destacados {
        font-size: 18px;
    }

    p.destacados {
        font-size: 11px;
    }

    div.destacados img {
        width: 75%;
    }
}