@font-face {
    font-family: Heavitas;
    src: url(../../Fonts/Heavitas.ttf);
}

div.envios {
    margin-top: 48px;
    background: #EEE;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

div.envios div p {
    color: #000;
    font-family: Heavitas,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

div.envios p span{
    font-family: Heavitas,sans-serif;
    color: #02425A;
    font-weight: 400;
}

@media(max-width:576px) {
    div.envios{
        margin-top: 50px;
    }
}