.loading-spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

.loading-container{
    display: flex;
    justify-content: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

h3.listalenceria {
    color: #000 !important;
    text-align: center;
    font-family: Kanit, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.color-div {
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

.AZUL {
    background-color: blue;
}

.NEGRO {
    background-color: black;
}

.ROJO {
    background-color: red;
}

.AVELLANA {
    background-color: #9F815E;
}

.AZULMARINO {
    background-color: #18155d;
}

.BLANCO {
    background-color: white;
}

.BORDO {
    background-color: #800000;
}

.FRANCIA {
    background-color: #0309CB;
}

.GRIS {
    background-color: grey;
}

.JEAN {
    background-color: #80ADC8;
}

.MALBEC {
    background-color: #532422;
}

.MARINO {
    background-color: #CCE4F7;
}

.MELANGE {
    background-color: #CF9E8F;
}

.NAUTICO {
    background-color: #5887C5;
}

.NEGROGRIS {
    background-color: #313133;
}

.PETROLEO {
    background-color: #3C4B55;
}

.BERRY {
    background-color: #9C1753;
}

.CARAMEL {
    background-color: #B26B30;
}

.CEBRA {
    background-image: linear-gradient(45deg,
            #ffffff 25%,
            #000000 25%,
            #000000 50%,
            #ffffff 50%,
            #ffffff 75%,
            #000000 75%,
            #000000 100%);
    background-size: 56.57px 56.57px;
}

.DAMERO {
    background-image: linear-gradient(45deg, #000 25%, transparent 25%, transparent 75%, #000 75%, #000),
        linear-gradient(45deg, #000 25%, #fff 25%, #fff 75%, #000 75%, #000);
    background-size: 4px 4px;
    background-position: 0 0, 30px 30px;
}

.LEO {
    background-color: #F26722;
}

.MAGNOLIA {
    background-color: #FFE1B3;
}

.SURTIDO {
    background-image:
        linear-gradient(90deg, #ff0000 25%, #0000ff 25%, #0000ff 50%, #008000 50%, #008000 75%, #ffff00 75%);
    background-size: 15px 15px;
}

.VERDE {
    background-color: green;
}

p.title {
    color: #000;
    text-align: center;
    font-family: Heavitas, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

p.subtitle {
    color: rgba(0, 0, 0, 0.80);
    font-family: Kanit, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

p.talles {
    color: #595959;
    text-align: center;
    font-family: sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

p.listalenceria {
    color: rgba(0, 0, 0);
    font-family: Kanit, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

div.listalenceria a {
    height: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

div.listalenceria a div.imgdatalista img {
    width: 80%;
    border-radius: 12px 12px 0 0;
    height: 450px;
    padding: 25px 0;
}

div.listalenceria a div.imgdatalista {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination {
    border: none;
    outline: none;
    padding: 6px 0;
    border-radius: 12px;
}

div.datalista {
    background-color: #ECECEC;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 12px 12px;
}

button.paginations {
    background-color: #D9D9D9;
    border: none;
    outline: none;
}

img.mujertalle {
    width: 80%;
}

img.otrostalle {
    width: 30%;
}

.pagination-arrow {
    background-color: #EFEFEF;
    border: none;
    border-radius: 12px;
}

.pagination-arrow:hover {
    background-color: #D9D9D9;
}

@media(max-width:450px) {
    div.listalenceria a {
        width: 120px;
    }

    div.listalenceria a div.imgdatalista img {
        height: 100%;
    }

    h3.listalenceria {
        font-size: 12px;
    }

    div.imgdatalista {
        height: 120px;
    }

    div.datalista {
        height: 100px;
    }

    img.mujertalle {
        width: 100%;
    }

    img.otrostalle {
        width: 51%;
    }
}

@media(min-width:450px) {
    div.listalenceria a {
        width: 200px;
    }

    div.datalista {
        height: 100px;
    }

    div.listalenceria a div.imgdatalista img {
        height: 240px;
    }

    h3.listalenceria {
        font-size: 12px;
    }
}

@media(min-width:576px) {
    div.listalenceria a {
        width: 25%;
    }

    div.listalenceria a div.imgdatalista img {
        height: 160px;
    }
}

@media(min-width:768px) {
    h3.listalenceria {
        width: 120px;
        font-size: 13px;
    }

    div.listalenceria a {
        width: 260px;
    }

    div.listalenceria div:not(.color-div) {
        width: 100%;
        position: relative;
    }

    div.imgdatalista {
        height: 300px;
    }

    div.datalista {
        height: 100px;
    }

    div.listalenceria a div.imgdatalista img {
        position: absolute;
        width: 70%;
        height: 100%;
    }

    img.ML {
        position: relative;
        top: -15px;
        width: 40px;
        height: 40px;
    }
}

@media(min-width:1200px) {

    div.listalenceria a {
        width: 250px;
    }

    div.listalenceria div:not(.color-div) {
        width: 100%;
        position: relative;
    }

    div.imgdatalista {
        height: 300px;
    }

    div.datalista {
        height: 100px;
    }

    div.listalenceria a div.imgdatalista img {
        position: absolute;
        width: 85%;
        height: 100%;
    }


}